import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 1,
    tokenName: 'MUFT',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9393d3C08956F245cdEE9ac9DD7214131Ae2bB8B',
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0xFCaB1CDB1049f89D5f2152E54a3a76D4Ddc67F24',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '',
    harvest: true,
    tokenPerBlock: '1157.4',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    rewardTokenDetails: {
      tokenAddress: '0x1D4a26a9D980AFC2287Dca15eb896A26384bAc7c',
      lpAddress: '0x8858cFb3f8C901381bd52F8aCf5bFB888dce421d',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    doomPitRewardPerBlock: 0.04,
  },
  {
    sousId: 2,
    tokenName: 'MEOW',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9393d3C08956F245cdEE9ac9DD7214131Ae2bB8B',
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0x3dE8A8FF4167479a8dc19E1D3E0016B9D80AdfE5',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://meowswap.net',
    harvest: true,
    tokenPerBlock: '0.04',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    rewardTokenDetails: {
      tokenAddress: '0xE8658B07c555E9604329A6a0A82FF6D9c6F68D2F',
      lpAddress: '0x828E02b67ccd4029285D8Ce999756c97c05e434F',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    doomPitRewardPerBlock: 1175.4,
  },

  //
  // {
  //   sousId: 0,
  //   tokenName: 'CAKE',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://pancakeswap.finance/',
  //   harvest: true,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   rewardTokenDetails: {
  //     tokenAddress: '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
  //     lpAddress: '0x0525334A5AFa3170B3D621B7130C2b068BDF9e43',
  //     quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //     quoteTokenSymbol: 'BNB',
  //   },
  // },
  // {
  //   sousId: 1,
  //   tokenName: 'TWT',
  //   stakingTokenName: QuoteToken.SYRUP,
  //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
]

export default pools

import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'MSWAP',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x90Ebf5bF9e0486893deBC85Dcf164d1dDC107e0c',
    },
    isTokenOnly: true,
    tokenSymbol: 'MSWAP',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x9393d3C08956F245cdEE9ac9DD7214131Ae2bB8B',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'MUFT',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x8858cFb3f8C901381bd52F8aCf5bFB888dce421d',
    },
    isTokenOnly: true,
    tokenSymbol: 'Muft',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x1d4a26a9d980afc2287dca15eb896a26384bac7c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'MSWAP-MUFT LP',
    lpAddresses: {
      97: '0x382c7e4111d072e37180c95ec627f760b75d4455',
      56: '0x0444457A8eb29e1Bdd13569c213F068b998f5660',
    },
    tokenSymbol: 'MSWAP',
    tokenAddresses: {
      97: '0x382c7e4111d072e37180c95ec627f760b75d4455',
      56: '0x9393d3C08956F245cdEE9ac9DD7214131Ae2bB8B',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'MSWAP-BNB LP',
    lpAddresses: {
      97: '0x382c7e4111d072e37180c95ec627f760b75d4455',
      56: '0x90Ebf5bF9e0486893deBC85Dcf164d1dDC107e0c',
    },
    tokenSymbol: 'MSWAP',
    tokenAddresses: {
      97: '0x382c7e4111d072e37180c95ec627f760b75d4455',
      56: '0x9393d3C08956F245cdEE9ac9DD7214131Ae2bB8B',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 4,
    risk: 5,
    lpSymbol: 'MUFT-BNB LP',
    lpAddresses: {
      97: '0x382c7e4111d072e37180c95ec627f760b75d4455',
      56: '0x8858cFb3f8C901381bd52F8aCf5bFB888dce421d',
    },
    tokenSymbol: 'MUFT',
    tokenAddresses: {
      97: '0x382c7e4111d072e37180c95ec627f760b75d4455',
      56: '0x1d4a26a9d980afc2287dca15eb896a26384bac7c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'ETHB',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x70D8929d04b60Af4fb9B58713eBcf18765aDE422',
    },
    isTokenOnly: true,
    tokenSymbol: 'ETHB',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 8,
    risk: 5,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x20bCC3b8a0091dDac2d0BC30F68E6CBb97de59Cd', // '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    isTokenOnly: true,
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 7,
    risk: 5,
    lpSymbol: 'BTCB',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x7561EEe90e24F3b348E1087A005F78B4c8453524',
    },
    isTokenOnly: true,
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 9,
    risk: 5,
    lpSymbol: 'AXS',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0xC2d00De94795e60FB76Bc37d899170996cBdA436', // '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    isTokenOnly: true,
    tokenSymbol: 'AXS',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 10,
    risk: 5,
    lpSymbol: 'SHIB',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x6b7b3523a6660a5fcE3c28E1536CC8dd8D57f7E0', // '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    isTokenOnly: true,
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 11,
    risk: 5,
    lpSymbol: 'GALA',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x8dd5Fc7941966448961250119db45af8aaA85D3f', // '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    isTokenOnly: true,
    tokenSymbol: 'GALA',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x7dDEE176F665cD201F93eEDE625770E2fD911990',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 12,
    risk: 5,
    lpSymbol: 'DOT',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF', // '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    isTokenOnly: true,
    tokenSymbol: 'DOT',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 13,
    risk: 5,
    lpSymbol: 'MATIC',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x5AcB99428a29092555dCDc00e4565B05272a3B2B', // '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    isTokenOnly: true,
    tokenSymbol: 'MATIC',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 14,
    risk: 5,
    lpSymbol: 'XRP',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x03F18135c44C64ebFdCBad8297fe5bDafdBbdd86', // '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    isTokenOnly: true,
    tokenSymbol: 'XRP',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 15,
    risk: 5,
    lpSymbol: 'ADA',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F', // '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    isTokenOnly: true,
    tokenSymbol: 'ADA',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 17,
    risk: 5,
    lpSymbol: 'MSWAP-BUSD LP',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x0C57013fb98cFb43B28551fE716b232Ee28d3886', // '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    // isTokenOnly: true,
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x9393d3c08956f245cdee9ac9dd7214131ae2bb8b',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 16,
    risk: 5,
    lpSymbol: 'MSWAP-USDT LP',
    lpAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x496508a60402bf0a0a3148A95976ec98C37bB224', // '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    // isTokenOnly: true,
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0xdA38540DcEf3Da8fA4906b8f11D49604d38628F1',
      56: '0x9393d3c08956f245cdee9ac9dd7214131ae2bb8b',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
]

export default farms

export const docsUrl = 'https://mswapdocs.gitbook.io/'
export const tokenomicsUrl = 'https://mswapdocs.gitbook.io/muft-and-muftswap/tokenomics/mswap'
export const roadmapUrl = 'https://mswapdocs.gitbook.io/muft-and-muftswap/fundamentals/roadmap'
export const chartUrl = 'https://poocoin.app/tokens/0x9393d3c08956f245cdee9ac9dd7214131ae2bb8b'
export const githubUrl = 'https://github.com/muftswap'
export const cmcUrl = 'https://coinmarketcap.com/currencies/muftswap/'
export const cmUrl = 'https://coinmooner.com/coin/6924/'
export const auditUrl =
  'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_ManUtdFantoken.pdf'
export const ftxUrl = 'https://blockfolio.com/coin/MUFT?timeFrame=1W'
export const buyMuftUrl =
  'https://trade.muftswap.exchange/swap?outputCurrency=0x1D4a26a9D980AFC2287Dca15eb896A26384bAc7c'
export const iloUrl = 'https://ilo.muftswap.exchange/'
export const swapUrl = 'https://trade.muftswap.exchange/#/swap'
export const liquidityUrl = 'https://trade.muftswap.exchange/#liquidity'
export const sportsPredictionUrl = 'https://sp.muftswap.exchange/'

/*
 * coinpaprika api list
 */
export const fetchMswapInfo = 'https://api.coinpaprika.com/v1/coins/mswap-muftswap-token/markets'
export const fetchMuftInfo = 'https://api.coinpaprika.com/v1/coins/muft-manutd-fan-token/markets'

import { ButtonMenu, ButtonMenuItem, Card, CardBody, Input, Text, CardHeader } from '@pancakeswap-libs/uikit'
import Page from 'components/layout/Page'
import React from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import Divider from 'views/Farms/components/Divider'

function Exchnage() {
  // const { url, isExact } = useRouteMatch()
  return (
    <Page style={{ alignItems: 'center' }}>
      <img src="https://i.pinimg.com/originals/5c/5d/66/5c5d6684644136c4b1442f1db30af6bf.gif" alt="commingsoon" />
    </Page>
  )
}

export default Exchnage

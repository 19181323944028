import React, { useContext, useEffect, useState } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import { fetchMswapInfo, fetchMuftInfo } from 'config/constants/endpoints'
import useTheme from 'hooks/useTheme'
import { usePriceMuftBusd, usePriceCakeBusd } from 'state/hooks'
import { Menu as UikitMenu } from '@pancakeswap-libs/uikit'
import config from './config'

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  // const cakePriceUsd = usePriceCakeBusd()
  // const cake2PriceUsd = usePriceMuftBusd()

  const [mswapPriceUsd, setMswapPriceUsd] = useState(0)
  const [muftPriceUsd, setMuftPriceUsd] = useState(0)

  // console.log(cakePriceUsd.toNumber(), 'cake2Price')

  const getMswapLivePrice = async () => {
    try {
      const response = await fetch(fetchMswapInfo)
      const data = await response.json()
      return data
    } catch (err) {
      console.log('bnbLivePriceError', err)
      throw err
    }
  }

  const getMuftLivePrice = async () => {
    try {
      const response = await fetch(fetchMuftInfo)
      const data = await response.json()
      return data
    } catch (err) {
      console.log('bnbLivePriceError', err)
      throw err
    }
  }

  useEffect(() => {
    getMswapLivePrice()
      .then((result) => {
        const mswapLivePrice = result[0].quotes.USD.price
        setMswapPriceUsd(mswapLivePrice)
      })
      .catch((err) => {
        console.log('call api err', err)
      })

    getMuftLivePrice()
      .then((result) => {
        const muftLivePrice = result[0].quotes.USD.price
        setMuftPriceUsd(muftLivePrice)
      })
      .catch((err) => {
        console.log('call api err', err)
      })
  }, [])

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={mswapPriceUsd}
      cake2PriceUsd={muftPriceUsd}
      links={config}
      priceLink="https://poocoin.app/tokens/0x9393d3c08956f245cdee9ac9dd7214131ae2bb8b"
      muftPriceLink="https://poocoin.app/tokens/0x1d4a26a9d980afc2287dca15eb896a26384bac7c"
      {...props}
    />
  )
}

export default Menu

import React from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { docsUrl, cmcUrl, cmUrl, auditUrl, iloUrl, swapUrl, liquidityUrl, sportsPredictionUrl } from 'config/constants/endpoints'

const RedirectContainer = styled.div`
  width: 100%;
  height: 100px;
  background-color: #4a0404;
  display: flex;
  align-items: center;
  justify-content: center;
`

const RedirectAudit = styled.p`
  font-size: 25px;
  color: white;
`

const Docs = () => {
  const history = useHistory()
  const location = useLocation()
  React.useEffect(() => {
    setTimeout(() => {
      let url = ''
      if (location.pathname === '/cmc') url = cmcUrl
      if (location.pathname === '/cm') url = cmUrl
      if (location.pathname === '/docs') url = docsUrl
      if (location.pathname === '/audit') url = auditUrl
      if (location.pathname === '/ilo') url = iloUrl
      if (location.pathname === '/swap') url = swapUrl
      if (location.pathname === '/liquidity') url = liquidityUrl
      if (location.pathname === '/prediction') url = sportsPredictionUrl
      if (location.pathname === '/cg') return
      if (location.pathname === '/ftx') return
      // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      const newWindow = window.open(url, '_self')
      if (newWindow) newWindow.opener = null
      history.push('/')
    }, 100)
  }, [history, location])
  return (
    <RedirectContainer>
      <RedirectAudit>Redirecting ...</RedirectAudit>
    </RedirectContainer>
  )
}

export default Docs
